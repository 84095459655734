<!-- el-form :model=props.propsValue 使用双向绑定直接修改父组件传入的propsValue，此处违反单向数据流但处理更简单 -->
<template>
  <div class="common-form-component">
    <el-form
      ref="formRef"
      :model="formData"
      :rules="props.rules"
      :label-position="labelPosition"
      :label-width="labelWidth"
    >
      <el-form-item
        v-for="(item, index) in propsList"
        :key="item.prop"
        :label-width="props.labelWidth"
        :label="props.showLable ? item.label : ''"
        :prop="item.prop"
        :rules="getFormItemRules(item)"
      >
        <template v-if="item.labelDesc" #label>
          <div class="el-form-item__label custom-label">
            {{ item.label }}
            <el-popover
              placement="top-start"
              :width="300"
              trigger="hover"
              :content="item.labelDesc || ''"
              popper-style="fontSize:12px"
            >
              <template #reference>
                <span>
                  <el-icon :size="15"><QuestionFilled /></el-icon>
                </span>
              </template>
            </el-popover>
          </div>
        </template>
        <slot v-if="item.slotName" :name="item.slotName" :prop="item"></slot>
        <template v-else>
          <!-- 嵌套的ref不会解包 -->
          <!-- 10 输入框 -->
          <el-input
            v-if="item.type == '10'"
            v-model="formData[item.prop]"
            :placeholder="'请输入' + item.label"
            :clearable="getClearable(item)"
            :disabled="getDisabled(item)"
            :readonly="getReadonly(item)"
            :maxlength="item.maxLength ?? 100"
          ></el-input>
          <!-- 11 文本域 -->
          <el-input
            v-if="item.type == '11'"
            type="textarea"
            v-model="formData[item.prop]"
            :placeholder="'请输入' + item.label"
            :clearable="getClearable(item)"
            :disabled="getDisabled(item)"
            :readonly="getReadonly(item)"
            :show-word-limit="!!(item.showWordLimit ?? (item.maxLength ? true : false))"
            :maxlength="item.maxLength"
            :rows="item.rows"
            :auto-size="!item.rows ? { minRows: item.minRows ?? 2 } : undefined"
          ></el-input>

          <!-- 20 日期区间选择框 -->
          <el-date-picker
            v-if="item.type == '20'"
            type="daterange"
            v-model="formData[item.prop]"
            value-format="YYYY-MM-DD"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :clearable="getClearable(item)"
            :disabled="getDisabled(item)"
          ></el-date-picker>
          <!-- 21 日期时间选择框 -->
          <el-date-picker
            v-if="item.type == '21'"
            type="datetime"
            v-model="formData[item.prop]"
            value-format="YYYY-MM-DD HH:mm:ss"
            :clearable="getClearable(item)"
            :disabled="getDisabled(item)"
          ></el-date-picker>
          <!-- 22 日期选择框 -->
          <el-date-picker
            v-if="item.type == '22'"
            type="date"
            v-model="formData[item.prop]"
            value-format="YYYY-MM-DD"
            :clearable="getClearable(item)"
            :disabled="getDisabled(item)"
          ></el-date-picker>
          <!-- 23 日期时间 区间选择框 -->
          <el-date-picker
            v-if="item.type == '23'"
            type="datetimerange"
            v-model="formData[item.prop]"
            value-format="YYYY-MM-DD HH:mm:ss"
            :clearable="getClearable(item)"
            :disabled="getDisabled(item)"
          ></el-date-picker>

          <!-- 30 下拉框 -->
          <el-select
            v-if="item.type == '30'"
            v-model="formData[item.prop]"
            :placeholder="'请选择' + item.label"
            :clearable="getClearable(item)"
            :disabled="getDisabled(item)"
            :filterable="item.filterable"
            :allow-create="item.allowCreate"
            :default-first-option="item.defaultFirstOption"
          >
            <!--  el-select 选项值集，默认数据结构[{label:'',value:''}] -->
            <el-option
              v-for="(optionItem, optionItemIndex) in item.options"
              :key="optionItemIndex"
              :label="optionItem[item?.optionItemKeysMap?.label || 'label']"
              :value="optionItem[item?.optionItemKeysMap?.value || 'value']"
            ></el-option>
          </el-select>

          <!-- 50 自动补全输入框 -->
          <el-autocomplete
            v-if="item.type == '50'"
            v-model="formData[item.prop]"
            :fetch-suggestions="item.queryFetch"
            :placeholder="'请选择' + item.label"
            :clearable="getClearable(item)"
            :disabled="getDisabled(item)"
          ></el-autocomplete>

          <!-- 60 多选 -->
          <el-checkbox-group
            v-if="item.type == '60' && item.options?.length"
            v-model="formData[item.prop]"
            :disabled="getDisabled(item)"
          >
            <el-checkbox
              v-for="(optionItem, optionItemIndex) in item.options"
              :key="optionItemIndex"
              :label="optionItem[item?.optionItemKeysMap?.value || 'value']"
              >{{ optionItem[item?.optionItemKeysMap?.label || 'label'] }}</el-checkbox
            >
          </el-checkbox-group>

          <!-- 70 单选 -->
          <el-radio-group v-if="item.type == '70'" v-model="formData[item.prop]" :disabled="getDisabled(item)">
            <el-radio
              v-for="(optionItem, optionItemIndex) in item.options"
              :key="optionItemIndex"
              :label="optionItem[item?.optionItemKeysMap?.value || 'value']"
              >{{ optionItem[item?.optionItemKeysMap?.label || 'label'] }}</el-radio
            >
          </el-radio-group>

          <!-- 80 文件上传 -->
          <file-upload
            :ref="el => setRef(el, item)"
            v-if="item.type == '80'"
            :button-text="item.buttonText"
            @uploadSuccess="uploadSuccessCB"
          />
        </template>
      </el-form-item>

      <el-form-item
        v-if="!props.readonly && !props.disabled && ['both', 'confirm', 'cancel'].indexOf(props.showBtn) !== -1"
        :label-width="props.labelWidth"
        label=""
        :class="['btn-block', props.btnPlacement]"
      >
        <el-button
          v-if="props.showBtn === 'both' || props.showBtn === 'confirm'"
          type="primary"
          size="default"
          :loading="props.loading"
          @click="handleSave"
          >{{ confirmButtonText }}</el-button
        >
        <el-button v-if="props.showBtn === 'both' || props.showBtn === 'cancel'" size="default" @click="handleCancel">{{
          cancelButtonText
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, toRefs, onBeforeMount, watch, nextTick } from 'vue'
import type { FormInstance, FormRules, FormItemRule } from 'element-plus'
import { BaseObject } from '@/types/index'
// import FileUpload from './FileUpload.vue'
import { dictionaryID } from '@/api/common'

// el-select options 选项类型
interface OptionItem {
  label?: string
  value?: any
  [index: string]: any
}
// el-option label/value 使用其他字段名的映射
interface OptionItemKeysMap {
  label: string
  value: string
}

// el-form-item 数据类型
interface FormItem {
  type: '10' | '11' | '20' | '21' | '22' | '23' | '30' | '40' | '50' | '60' | '70' | '80' | '81' | '82' // 10:el-input、 11:el-input.textarea， 20:el-date-picker[daterange]、21:el-date-picker[datetime]、22:el-date-picker[date]、23:el-date-picker[datetimerange]， 30:el-select， 50:el-autocomplete， 60复选框，  70:单选框， 80:图片上传、81文件上传、82文件上传弹框
  label: string // 表单项 label
  prop: string // 表单项 prop
  clearable?: boolean // 表单项 是否可清除，默认 true
  options?: OptionItem[] // 表单项是el-select且选项是值集时，查询到的选项列表，接口数据结构 [{value:'', name:''}]
  valueSetCode?: string // 表单项是el-select且选项是值集时，查询值集的code
  optionItemKeysMap?: OptionItemKeysMap // 表单项是el-select且选项非值集时，<el-option>使用的属性的映射
  slotName?: string // 插槽名，有值即表示使用插槽
  disabled?: boolean // 是否禁用
  readonly?: boolean // 是否只读
  elColSpan?: number // el-form-item :span的值
  showAllLevels?: boolean // 表单项是el-cascader时，show-all-levels
  queryFetch?: (queryString: string, cb: any) => void // 表单项是el-autocomplete时，fetch-suggestions
  maxLength?: number
  showWordLimit?: boolean
  labelDesc?: string //el-form-item label 描述信息
  buttonText?: string //80上传组件，按钮文字
  rows?: number //11textarea
  minRows?: number //11 textarea
  autoSize?: {
    type: object
    default: () => { minRows: 2 } //minRows: 2; maxRows: 4
  } //11 textarea
  filterable?: boolean //30 el-select
  allowCreate?: boolean //30 el-select
  defaultFirstOption?: boolean //30 el-select
  rules?: FormItemRule //表单项rules
  required?: boolean //根据type自动生成对应的必填rules
  labelPath?: string
}

type ShowBtn = 'both' | 'confirm' | 'cancel' | 'none'

// 如何定义PropsValue的对象类型，使包含所有propsList的prop，运行时确定propsList，无法校验？？？TODO
// interface PropsValue<T extends FormItem> {
//   [p in keyof T]:any
// }

// props的类型声明、默认值。（vue3要求，类型声明参数：类型字面量；在同一文件中的接口或类型字面量的引用）
const props = withDefaults(
  defineProps<{
    propsList: FormItem[]
    propsValue: BaseObject
    labelWidth?: string | number
    elColSpan?: number
    showLable?: boolean
    rules?: FormRules
    btnPlacement?: string
    labelPosition?: string
    clearable?: boolean // 表单项 是否可清除，默认 true
    disabled?: boolean // 是否禁用
    readonly?: boolean // 是否只读
    showBtn?: ShowBtn //是否展示按钮
    loading?: boolean
    confirmButtonText?: string
    cancelButtonText?: string
  }>(),
  {
    propsList: () => [],
    propsValue: () => ({}),
    labelWidth: 'auto',
    elColSpan: 6,
    showLable: true,
    btnPlacement: 'left',
    labelPosition: 'top',
    clearable: true,
    disabled: false,
    readonly: false,
    showBtn: 'both',
    loading: false,
    confirmButtonText: '保存',
    cancelButtonText: '取消',
  },
)
// let formData = toRefs(props.propsValue) //defineProps声明的属性，不可修改，vue已设置为readonly，但是props.key.key可修改。propsValue是在父组件声明的reactive数据，传入子组件保持响应式
let formData = props.propsValue //formRef.value?.validate校验的值是原始reactive值，toRefs包括后无法校验

const emit = defineEmits(['cancel', 'confirm']) //emit 第一个参数被vue定义为defineEmits的联合类型

const formRef = ref<FormInstance>()

// 获取表单项禁用状态，表单项配置优先于表单配置，默认false
const getDisabled = (item: FormItem) => {
  if ('disabled' in item) {
    return !!item.disabled
  }
  if ('disabled' in props) {
    return !!props.disabled
  }
  return false
}

const getReadonly = (item: FormItem) => {
  if ('readonly' in item) {
    return !!item.readonly
  }
  if ('readonly' in props) {
    return !!props.readonly
  }
  return false
}

const getClearable = (item: FormItem) => {
  if ('clearable' in item) {
    return !!item.clearable
  }
  if ('clearable' in props) {
    return !!props.clearable
  }
  return true
}

//生成表单项校验规则
const getFormItemRules = (item: FormItem) => {
  if (item.rules) {
    return item.rules
  } else if (item.required) {
    let arr: any = []
    if (item.type === '10' || item.type === '11') {
      arr = [{ required: true, message: '请填写', trigger: 'blur' }]
    } else if (['20', '21', '22', '23', '30', '50', '60', '70'].indexOf(item.type) != -1) {
      arr = [{ required: true, message: '请选择', trigger: 'change' }]
    }
    return arr
  }
  return undefined
}

// 重置表单
const resetFields = () => {
  formRef.value?.resetFields()
}
// 清空输入校验
const clearValidate = () => {
  formRef.value?.clearValidate()
}

// 表单校验，返回promise，使用await获取结果
const validateFn = () => {
  return formRef.value?.validate(valid => {
    if (valid) {
      return true
    } else {
      return false
    }
  })
}

// 向父组件暴露当前住组件的方法
defineExpose({
  resetFields,
  clearValidate,
  validateFn,
})

// 80 文件上传 为各个表单项组件设置ref
let refMap = reactive<BaseObject>({})
const setRef = (el, item) => {
  refMap[item.prop] = el
}

// 取消按钮
const handleCancel = () => {
  emit('cancel')
  clearValidate()
}

// 保存按钮
const handleSave = () => {
  formRef.value?.validate(valid => {
    if (valid) {
      nextTick(async () => {
        await refMap.file?.confirmUpload() //默认当前只注册了一个上传组件，且表单项prop值是file TODO
        emit('confirm', formData)
      })
    } else {
      // ElMessage.error('请检查输入')
    }
  })
}

// 上传组件80：文件上传成功回调
const uploadSuccessCB = (data: any) => {
  console.log('uploadSuccessCB')
  formData.file = data || {}
}

onBeforeMount(() => {
  // 数据字典：el-select 选项值集，构造数据结构[{label:'',value:''}]。
  props.propsList.map(item => {
    if (item.valueSetCode) {
      dictionaryID({ id: item.valueSetCode })
        .then((res: any) => {
          if (res.data && res.data.length) {
            item.options = res.data.map((item: any) => ({ ...item, label: item.text }))
          } else {
            item.options = []
          }
        })
        .catch(error => {
          console.log('CommonSearch-dictionaryID-error', error)
        })
    }
  })
})
onMounted(() => {})
</script>

<style scoped lang="less">
.common-form-component {
  :deep .el-form-item {
    margin-bottom: 20px !important;
  }
  .btn-block {
    &.left {
      :deep .el-form-item__content {
        justify-content: flex-start;
      }
    }
    &.right {
      :deep .el-form-item__content {
        justify-content: flex-end;
      }
    }
  }
  .custom-label {
    display: inline-block;
    margin-bottom: 0 !important;
    .el-tooltip__trigger {
      vertical-align: middle;
      margin-left: 3px;
    }
  }
  :deep .el-upload-list {
    min-width: 200px;
  }
  :deep .el-input {
    width: 250px;
    height: 32px;
  }
  :deep .el-select {
    width: 250px;
    height: 32px;
  }
  :deep .el-autocomplete {
    width: 250px;
    height: 32px;
  }
  :deep .el-textarea {
    width: 400px;
  }
}
</style>

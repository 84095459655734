import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "common-search-component" }

import { onMounted, reactive, ref, toRefs, onBeforeMount, watch } from 'vue'
import { BaseObject } from '@/types/index'
import { dictionaryID } from '@/api/common'

// el-select options 选项类型
interface OptionItem {
  label?: string
  value?: any
  [index: string]: any
}
// el-option label/value 使用其他字段名的映射
interface OptionItemKeysMap {
  label: string
  value: string
}

interface CascaderProps {
  checkStrictly?: boolean
  value?: string
  label?: string
  lazy?: boolean
  lazyLoad?: (node: any, resolve: any) => void
  [index: string]: any
}

// el-form-item 数据类型
interface SearchItem {
  type: '10' | '20' | '21' | '30' | '40' | '50' | '60' // 10:el-input, 20:el-date-picker, 30:el-select,  40:el-cascader,  50:el-autocomplete,
  label: string // 表单项 label
  prop: string // 表单项 prop
  clearable?: boolean // 表单项 是否可清除，默认 true
  options?: OptionItem[] // 表单项是el-select且选项是值集时，查询到的选项列表，接口数据结构 [{value:'', name:''}]
  valueSetCode?: string // 表单项是el-select且选项是值集时，查询值集的code
  optionItemKeysMap?: OptionItemKeysMap // 表单项是el-select且选项非值集时，<el-option>使用的属性的映射
  slotName?: string // 插槽名，有值即表示使用插槽
  disabled?: boolean // 是否禁用
  elColSpan?: number // el-form-item :span的值
  cascaderProps?: CascaderProps // 表单项是el-cascader时，props
  showAllLevels?: boolean // 表单项是el-cascader时，show-all-levels
  queryFetch?: (queryString: string, cb: any) => void // 表单项是el-autocomplete时，fetch-suggestions
  shortcuts?: any[] //20 21 时间选择框 快捷选项
  disabledDate?: any //20 21 时间选择框 禁止选择的日期
  collapseTags?: boolean
  filterable?: boolean //el-select 的 filterable
  multiple?: boolean //el-select 的 multiple
  iconName?: string //表单项icon，配合<svg-icon>组件
}
// 如何定义PropsValue的对象类型，使包含所有propsList的prop，运行时确定propsList，无法校验？？？TODO
// interface PropsValue<T extends SearchItem> {
//   [p in keyof T]:any
// }
// props的类型声明、默认值。（vue3要求，类型声明参数：类型字面量；在同一文件中的接口或类型字面量的引用）

export default /*@__PURE__*/_defineComponent({
  __name: 'CommonSearchNew',
  props: {
    propsList: { default: () => [] },
    propsValue: { default: () => ({}) },
    labelWidth: { default: 'auto' },
    elColSpan: { default: 6 },
    showLable: { type: Boolean, default: true },
    labelPosition: { default: 'left' },
    showRest: { type: Boolean, default: true }
  },
  emits: ['resetAndQuery', 'query'],
  setup(__props: any, { emit: __emit }) {

const props = __props
let formData = toRefs(props.propsValue) //defineProps声明的属性，不可修改，vue已设置为readonly，但是props.key.key可修改。propsValue是在父组件声明的reactive数据，传入子组件保持响应式

const emit = __emit
const handleEmit = (type: 'resetAndQuery' | 'query') => {
  //此处 type 不能使用 type:string，emit 第一个参数被vue定义为defineEmits的联合类型
  emit(type)
}

const getOptions = (item: SearchItem) => {
  dictionaryID({ id: item.valueSetCode })
    .then((res: any) => {
      if (res.data && res.data.length) {
        item.options = res.data.map((item: any) => ({ ...item, label: item.text }))
      } else {
        item.options = []
      }
      // el-select先赋值v-modle，后更新el-option，没有显示正确的lable，两次更新v-model触发再次渲染
      let currentValue = props.propsValue[item.prop]
      if (currentValue !== undefined && currentValue !== null) {
        setTimeout(() => {
          formData[item.prop].value = undefined
          formData[item.prop].value = currentValue
        }, 0)
      }
      console.log('dictionaryID', item.valueSetCode, typeof currentValue)
    })
    .catch(error => {
      console.log('CommonSearch-dictionaryID-error', error)
    })
}

watch(
  () => props.propsList,
  newValue => {
    props.propsList.map(item => {
      if (item.valueSetCode) {
        getOptions(item)
      }
    })
  },
)

onBeforeMount(() => {
  // 数据字典：el-select 选项值集，构造数据结构[{label:'',value:''}]。
  props.propsList.map(item => {
    if (item.valueSetCode) {
      getOptions(item)
    }
  })
})

const handleChangeCascader = value => {
  console.log('handleChangeCascader', value)
}
onMounted(() => {})

return (_ctx: any,_cache: any) => {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: _unref(formData),
      inline: true,
      "label-position": props.labelPosition
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propsList, (item) => {
              return (_openBlock(), _createBlock(_component_el_col, {
                span: item.elColSpan || props.elColSpan || 6,
                class: "search-form-item",
                key: item.prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    class: "item-style",
                    "label-width": props.labelWidth,
                    label: props.showLable ? item.label : '',
                    prop: item.prop
                  }, _createSlots({
                    default: _withCtx(() => [
                      (item.slotName)
                        ? _renderSlot(_ctx.$slots, item.slotName, {
                            key: 0,
                            prop: item
                          })
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (item.type == '10')
                              ? (_openBlock(), _createBlock(_component_el_input, {
                                  key: 0,
                                  modelValue: _unref(formData)[item.prop].value,
                                  "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop].value) = $event),
                                  placeholder: '请输入' + item.label,
                                  clearable: 'clearable' in item ? !!item.clearable : true,
                                  disabled: 'disabled' in item ? !!item.disabled : false
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "clearable", "disabled"]))
                              : _createCommentVNode("", true),
                            (item.type == '20')
                              ? (_openBlock(), _createBlock(_component_el_date_picker, {
                                  key: 1,
                                  type: "daterange",
                                  modelValue: _unref(formData)[item.prop].value,
                                  "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop].value) = $event),
                                  "value-format": "YYYY-MM-DD",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  clearable: 'clearable' in item ? !!item.clearable : true,
                                  disabled: 'disabled' in item ? !!item.disabled : false
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "clearable", "disabled"]))
                              : _createCommentVNode("", true),
                            (item.type == '21')
                              ? (_openBlock(), _createBlock(_component_el_date_picker, {
                                  key: 2,
                                  type: "datetimerange",
                                  modelValue: _unref(formData)[item.prop].value,
                                  "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop].value) = $event),
                                  "value-format": "YYYY-MM-DD HH:mm:ss",
                                  "start-placeholder": "开始时间",
                                  "end-placeholder": "结束时间",
                                  shortcuts: item.shortcuts,
                                  "disabled-date": item.disabledDate,
                                  clearable: 'clearable' in item ? !!item.clearable : true,
                                  disabled: 'disabled' in item ? !!item.disabled : false
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "shortcuts", "disabled-date", "clearable", "disabled"]))
                              : _createCommentVNode("", true),
                            (item.type == '30')
                              ? (_openBlock(), _createBlock(_component_el_select, {
                                  key: 3,
                                  modelValue: _unref(formData)[item.prop].value,
                                  "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop].value) = $event),
                                  placeholder: '请选择' + item.label,
                                  clearable: 'clearable' in item ? !!item.clearable : true,
                                  disabled: 'disabled' in item ? !!item.disabled : false,
                                  filterable: 'filterable' in item ? !!item.filterable : false,
                                  multiple: 'multiple' in item ? !!item.multiple : false,
                                  "collapse-tags": true
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (optionItem, optionItemIndex) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: optionItemIndex,
                                        label: optionItem[item?.optionItemKeysMap?.label || 'label'],
                                        value: optionItem[item?.optionItemKeysMap?.value || 'value']
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder", "clearable", "disabled", "filterable", "multiple"]))
                              : _createCommentVNode("", true),
                            (item.type == '40')
                              ? (_openBlock(), _createBlock(_component_el_cascader, {
                                  key: 4,
                                  modelValue: _unref(formData)[item.prop].value,
                                  "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop].value) = $event),
                                  options: item.options,
                                  props: item.cascaderProps,
                                  placeholder: '请选择' + item.label,
                                  clearable: 'clearable' in item ? !!item.clearable : true,
                                  disabled: 'disabled' in item ? !!item.disabled : false,
                                  "collapse-tags": item.collapseTags,
                                  onChange: handleChangeCascader
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "props", "placeholder", "clearable", "disabled", "collapse-tags"]))
                              : _createCommentVNode("", true),
                            (item.type == '50')
                              ? (_openBlock(), _createBlock(_component_el_autocomplete, {
                                  key: 5,
                                  modelValue: _unref(formData)[item.prop].value,
                                  "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop].value) = $event),
                                  "fetch-suggestions": item.queryFetch,
                                  placeholder: '请选择' + item.label,
                                  clearable: 'clearable' in item ? !!item.clearable : true,
                                  disabled: 'disabled' in item ? !!item.disabled : false
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "placeholder", "clearable", "disabled"]))
                              : _createCommentVNode("", true)
                          ], 64))
                    ]),
                    _: 2
                  }, [
                    (item.iconName)
                      ? {
                          name: "label",
                          fn: _withCtx(() => [
                            _createElementVNode("span", null, [
                              _createVNode(_component_svg_icon, {
                                iconName: item.iconName,
                                size: 18,
                                color: "#606266"
                              }, null, 8, ["iconName"]),
                              _createTextVNode(" " + _toDisplayString(props.showLable ? item.label : ''), 1)
                            ])
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["label-width", "label", "prop"])
                ]),
                _: 2
              }, 1032, ["span"]))
            }), 128)),
            _createVNode(_component_el_col, {
              span: _ctx.propsList.length <= 2 ? 8 : 4,
              class: "search-form-item"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    (_ctx.showRest)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          size: "default",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleEmit('resetAndQuery')))
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("重置")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "default",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (handleEmit('query')))
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("查询")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["span"])
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["model", "label-position"])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList } from "vue"

import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { RouteRecordRaw } from 'vue-router'
import path from 'path-browserify'
import { isExternal } from '@/utils/index'


export default /*@__PURE__*/_defineComponent({
  __name: 'NavMenuItem',
  props: {
    item: {},
    basePath: {},
    mode: { default: 'vertical' },
    collapse: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props

let router = useRouter()
const skipRoute = (path: string) => {
  router.push(path)
}

const onlyOneChild = ref() // 临时变量，唯一子路由

/**
 * 判断当前路由是否只有一个子路由
 *
 * 1：如果只有一个子路由： 返回 true
 * 2：如果无子路由 ：返回 true
 *
 * @param children 子路由数组
 * @param parent 当前路由
 */
function hasOneShowingChild(children = [], parent: any) {
  // 需要显示的子路由数组
  const showingChildren = children.filter((item: any) => {
    if (item.meta?.hidden) {
      return false // 过滤不显示的子路由
    } else {
      onlyOneChild.value = item // 唯一子路由赋值（多个子路由情况 onlyOneChild 变量是用不上的）
      return true
    }
  })

  // 1：如果只有一个子路由, 返回 true
  if (showingChildren.length === 1) {
    return true
  }

  // 2：如果无子路由, 复制当前路由信息作为其子路由，满足只拥有一个子路由的条件，所以返回 true
  if (showingChildren.length === 0) {
    onlyOneChild.value = { ...parent, path: '', noShowingChildren: true }
    return true
  }
  return false
}

/**
 * 解析路径
 *
 * @param routePath 路由路径
 */
function resolvePath(routePath: string) {
  if (isExternal(routePath)) {
    return routePath
  }
  if (isExternal(props.basePath)) {
    return props.basePath
  }
  // 完整路径 = 父级路径(/level/level_3) + 路由路径
  const fullPath = path.resolve(props.basePath, routePath) // 相对路径 → 绝对路径
  return fullPath
}

let defaultActive = ref()
watch(
  () => router.currentRoute.value,
  (newValue, oldValue) => {
    defaultActive.value = newValue.path
  },
  {
    immediate: true,
  },
)

// 导航图标是否高亮
const getIconColor = (path: string, isElMenuTitle?: string) => {
  // console.log('getIconColor', path, props)
  // 折叠el-menu时，element-plus封装组件时，el-menu-item 默认使用了 el-tooltip展示 #title插槽，提示框背景色默认是灰色
  // 折叠时，嵌套的nav-menu弹窗不可使用白色，此处暂时写死，TODO
  let oneChildNameList = ['Home', 'AlarmList', 'DataAnalysis', 'RunningAnalysis', 'WorkOrder']
  if (
    isElMenuTitle &&
    !!oneChildNameList.find(item => item === props.item.name) &&
    props.mode === 'vertical' &&
    props.collapse === true
  ) {
    return '#fff'
  }
  if (defaultActive.value === path || defaultActive.value?.indexOf(path) === 0) {
    return '#eb853b'
  }
  return '#333'
}

// onBeforeMount(() => {
//   defaultActive.value = router.currentRoute.value.path
// })

return (_ctx: any,_cache: any) => {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_nav_menu_item = _resolveComponent("nav-menu-item", true)!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!

  return (!_ctx.item.meta?.hidden)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (hasOneShowingChild(_ctx.item.children, _ctx.item) && _ctx.item.meta?.alwaysShow !== true)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (onlyOneChild.value.meta)
                ? (_openBlock(), _createBlock(_component_el_menu_item, {
                    key: 0,
                    index: resolvePath(onlyOneChild.value.path),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (skipRoute(resolvePath(onlyOneChild.value.path))))
                  }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", {
                        class: "ellipsis",
                        style: _normalizeStyle({ color: getIconColor(resolvePath(onlyOneChild.value.path), 'isElMenuTitle') })
                      }, _toDisplayString(onlyOneChild.value?.meta?.title), 5)
                    ]),
                    default: _withCtx(() => [
                      (_ctx.mode === 'vertical' && onlyOneChild.value.meta?.icon)
                        ? (_openBlock(), _createBlock(_component_el_icon, {
                            key: 0,
                            size: 16
                          }, {
                            default: _withCtx(() => [
                              (_ctx.mode === 'vertical')
                                ? (_openBlock(), _createBlock(_component_svg_icon, {
                                    key: 0,
                                    iconName: onlyOneChild.value.meta?.icon,
                                    color: getIconColor(resolvePath(onlyOneChild.value.path))
                                  }, null, 8, ["iconName", "color"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["index"]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 1,
              index: resolvePath(_ctx.item.path)
            }, {
              title: _withCtx(() => [
                (_ctx.mode === 'vertical' && _ctx.item.meta?.icon)
                  ? (_openBlock(), _createBlock(_component_el_icon, {
                      key: 0,
                      size: 16
                    }, {
                      default: _withCtx(() => [
                        (_ctx.mode === 'vertical')
                          ? (_openBlock(), _createBlock(_component_svg_icon, {
                              key: 0,
                              iconName: _ctx.item.meta?.icon,
                              color: getIconColor(resolvePath(_ctx.item.path))
                            }, null, 8, ["iconName", "color"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("span", {
                  style: _normalizeStyle({ color: getIconColor(resolvePath(_ctx.item.path)) })
                }, _toDisplayString(_ctx.item.meta?.title), 5)
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
                  return (_openBlock(), _createBlock(_component_nav_menu_item, {
                    key: child.path,
                    item: child,
                    "base-path": resolvePath(child.path),
                    mode: _ctx.mode,
                    collapse: props.collapse
                  }, null, 8, ["item", "base-path", "mode", "collapse"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["index"]))
      ], 64))
    : _createCommentVNode("", true)
}
}

})
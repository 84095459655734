<template>
  <svg
    :class="svgClass"
    aria-hidden="true"
    :fill="color"
    :style="{ width: props.size + 'px', height: props.size + 'px' }"
  >
    <use :xlink:href="iconClassName" :fill="color" />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  iconName: {
    type: String,
    required: true,
  },
  className: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    default: '#409eff',
  },
  // icon 图表是否展示右边距，当图标放在导航菜单时，默认显示边距
  iconMargin: {
    type: Boolean,
    default: true,
  },
  size: {
    type: Number,
    default: 16,
  },
})

// 图标在 iconfont 中的名字
const iconClassName = computed(() => {
  // return props.iconName.indexOf('../') == -1 ? `#${props.iconName}` : require(props.iconName)
  return `#${props.iconName}`
})

// 给图标添加上类名
const svgClass = computed(() => {
  if (props.className) {
    return `svg-icon ${props.className} ${props.iconMargin ? 'margin-right' : ''}`
  }
  return 'svg-icon'
})
</script>

<style scoped lang="less">
.svg-icon {
  // width: 16px;
  // height: 16px;
  position: relative;
  fill: currentColor;
  vertical-align: -2px;
  &.margin-right {
    margin-right: 8px;
  }
}
</style>

import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      isCollapse: false,
    }
  },
  actions: {
    toggleIsCollapse(state: boolean) {
      this.isCollapse = state
    },
  },
})

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["fill"]
const _hoisted_2 = ["xlink:href", "fill"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SvgIcon',
  props: {
  iconName: {
    type: String,
    required: true,
  },
  className: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    default: '#409eff',
  },
  // icon 图表是否展示右边距，当图标放在导航菜单时，默认显示边距
  iconMargin: {
    type: Boolean,
    default: true,
  },
  size: {
    type: Number,
    default: 16,
  },
},
  setup(__props) {

const props = __props

// 图标在 iconfont 中的名字
const iconClassName = computed(() => {
  // return props.iconName.indexOf('../') == -1 ? `#${props.iconName}` : require(props.iconName)
  return `#${props.iconName}`
})

// 给图标添加上类名
const svgClass = computed(() => {
  if (props.className) {
    return `svg-icon ${props.className} ${props.iconMargin ? 'margin-right' : ''}`
  }
  return 'svg-icon'
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(svgClass.value),
    "aria-hidden": "true",
    fill: __props.color,
    style: _normalizeStyle({ width: props.size + 'px', height: props.size + 'px' })
  }, [
    _createElementVNode("use", {
      "xlink:href": iconClassName.value,
      fill: __props.color
    }, null, 8, _hoisted_2)
  ], 14, _hoisted_1))
}
}

})
import { BaseObject } from '@/types'

// 折线面积图渐变色，折线颜色border，面积gradient
export const colorMap = {
  blueGradient: {
    border: '#0180ff',
    gradient: ['#81befd', '#e4f2ff', '#fff'],
  },
  greenGradient: {
    border: '#4fd6d2',
    gradient: ['#d7f4f8', '#eefcfd', '#fff'],
  },
  purpleGradient: {
    border: '#706be7',
    // gradient: ['#e7effe', '#f2f6ff', '#fff'],
    gradient: ['#a6a2f4', '#e7effe', '#fff'],
  },
  yellowGradient: {
    border: '#e2af23',
    gradient: ['#fdd055', '#fff8eb', '#fff'],
  },
}

export const getTooltipFormatterObj = (oldName?: string, newName?: string) => {
  return {
    formatter: function (params: any) {
      // console.log(params)

      // 替换tooltip中展示的名称 monomerVoltage -- 电芯
      const formatName = (name: string) => {
        if (oldName && newName) {
          return name.replace(oldName, newName)
        } else {
          return name
        }
      }

      let dom = '<div style="width:460px; height:150px;  font-size: 14px; color:#333; overflow-y:auto;">'
      dom += '<div>' + params[0].axisValue + '</div>'
      dom += '<div style="display:flex; flex-flow:row wrap;">'
      if (Array.isArray(params)) {
        // 最大值、最小值
        const arr = params.filter(item => item.value).map(item => Number(item.value)) //过滤没有值的数据
        const max = arr?.length ? Math.max(...arr) : ''
        const min = arr?.length ? Math.min(...arr) : ''
        // console.log(arr, max, min)

        // 最大值、最小值对应的编号
        const maxItem = params.find((item: any) => Number(item.value) === max)
        const maxNumString = maxItem?.seriesName ? `（${formatName(maxItem.seriesName)}）` : ''
        const minItem = params.find((item: any) => Number(item.value) === min)
        const minNumString = minItem?.seriesName ? `（${formatName(minItem.seriesName)}）` : ''

        dom += '<div style="width:100%; font-weight:700;">'
        dom +=
          '<span style="display:inline-block; padding:5px 10px; color:#fac858;">最大值： ' +
          (max || '') +
          maxNumString +
          '</span>'
        dom +=
          '<span style="display:inline-block; padding:5px 10px; color:#3ba272;">最小值： ' +
          (min || '') +
          minNumString +
          '</span>'
        dom += '</div>'

        params.map(item => {
          dom += '<span style="display:inline-block; padding:5px 10px 0 10px; ">'
          // dom += item.marker
          dom +=
            '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color: #abbbc6;"></span>'
          dom +=
            '<span style="display:inline-block; min-width:55px; margin-left: 4px;">' +
            formatName(item.seriesName) +
            '</span>'

          let styleString = 'display:inline-block; width:35px; margin-left: 5px;'
          if (item.value === min) {
            styleString += 'font-weight:700; color:#3ba272'
          } else if (item.value === max) {
            styleString += 'font-weight:700; color:#fac858'
          }
          dom += '<span style="' + styleString + '">' + (item.value || '') + '</span>'
          dom += '</span>'
        })
      }
      dom += '</div></div>'
      return dom
    },
  }
}

// 折线图  name Y轴名称，customOptions 自定义配置项，openCustomTooltip 是否使用默认的tooltip配置
export const getLineOptions = (name: string, customOptions: BaseObject, openCustomTooltip?: boolean) => {
  // tooltip默认样式
  let tooltipFormatterObj = {}
  if (openCustomTooltip) {
    tooltipFormatterObj = getTooltipFormatterObj()
  }

  return {
    tooltip: {
      trigger: 'axis',
      enterable: !!openCustomTooltip, //鼠标是否可进入提示框浮层中
      confine: true, //是否将 tooltip 框限制在图表的区域内
      ...tooltipFormatterObj,
    },
    legend: {
      show: false,
    },
    calculable: true,
    grid: {
      top: '12%',
      right: '5%',
      bottom: '5%',
      left: '5%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      // name: '时间',
      name: '',
      nameTextStyle: {
        padding: [0, 0, 0, -5],
      },
      axisTick: {
        alignWithLabel: true,
      },
      //设置 X 轴的样式
      axisLine: {
        lineStyle: {
          show: true,
          color: '#000',
          width: 1,
        },
      },
      //设置 X 轴的样式，浅色样式
      // axisLine: {
      //   lineStyle: {
      //     show: true,
      //     type: 'dashed',
      //     color: '#efefef',
      //     width: 1,
      //   },
      // },
      // axisLabel: {
      //   color: '#b7b8bf',
      // },
      data: [] as any[],
    },
    yAxis: [
      {
        name,
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        nameTextStyle: {
          padding: [0, 16, -4, 20],
        },
        //Echarts-将图表网格线设置成虚线（图表横线网格）
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: '#efefef',
          },
          show: true,
        },
        axisLine: {
          lineStyle: {
            show: true,
            color: '#000',
            width: 1,
          },
          show: true,
        },
        // 浅色样式
        // axisLine: {
        //   lineStyle: {
        //     show: true,
        //     type: 'dashed',
        //     color: '#efefef',
        //     width: 1,
        //   },
        //   show: true,
        // },
        // axisLabel: {
        //   show: true,
        //   color: '#b7b8bf',
        // },
        axisLabel: {
          showMinLabel: true,
          showMaxLabel: true,
        },
      },
    ],
    series: [] as any[],
    ...customOptions,
  }
}
// 盒须图
export const getBoxplotChartOptions = (title: string, yAxisName: string, xAxisData?: any[]) => {
  let test: any[] = []
  if (xAxisData) {
    // test = [
    //   {
    //     transform: {
    //       type: 'boxplot',
    //       config: {
    //         //添加左三盒须图表X轴的时间坐标
    //         itemNameFormatter: function (params: any) {
    //           console.log('itemNameFormatter', params)
    //           return xAxisData[params.value]
    //         },
    //       },
    //     },
    //   },
    // ]
  }
  return {
    grid: {
      bottom: '9%',
    },
    // grid: {
    //   left: '5%',
    //   top: '12%',
    //   right: '5%',
    //   bottom: '5%',
    //   containLabel: true,
    // },
    title: [
      {
        text: title,
        left: 'center',
        padding: [5, 15, 15, 25],
        textStyle: {
          color: '#000',
          fontStyle: 'normal',
          fontWeight: '400',
          fontFamily: 'sans-serif',
          fontSize: 14,
        },
      },
    ],
    dataset: [
      {
        source: [] as any[],
      },
      ...test,
    ],
    tooltip: {
      trigger: 'item',
      confine: true,
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      name: '时间',
      nameTextStyle: {
        padding: [0, 0, 0, -10],
      },
      type: 'category',
      boundaryGap: true,
      nameGap: 20,
      splitArea: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          show: true,
          color: '#000',
          width: 1,
        },
      },
    },
    yAxis: {
      name: yAxisName,
      type: 'value',
      nameTextStyle: {
        padding: [0, 16, -4, 20],
      },
      min: 'dataMin', //取最小值为最小刻度
      max: 'dataMax', //取最大值为最大刻度
      //Echarts-将图表网格线设置成虚线（图表横线网格）
      // splitLine: {
      //   lineStyle: {
      //     type: 'dashed',
      //     color: '#464A4E',
      //   },
      //   show: true,
      // },
      // splitArea: {
      //   show: true,
      // },
      //设置 Y 轴的样式
      axisLine: {
        lineStyle: {
          show: true,
          color: '#000',
          width: 1,
        },
      },
    },
    series: [
      {
        name: title,
        type: 'boxplot',
        datasetIndex: 1,
        boxWidth: [3, 30],
        // encode: {
        //   tooltip: ['min', 'Q1', 'median', 'Q3', 'max'],
        // },
        tooltip: {
          // trigger: 'axis',
          enterable: true, //鼠标是否可进入提示框浮层中
          confine: true, //是否将 tooltip 框限制在图表的区域内
          formatter: function (params: any) {
            console.log(params)
            let dom = '<div style="width:130px; height:190px;  font-size: 14px; color:#333; overflow-y:auto;">'
            dom +=
              '<div style="display:flex; justify-content: space-between; padding:5px 0;"><span>时间：</span>' +
              params.name +
              '</div>'
            dom +=
              '<div style="display:flex; justify-content: space-between; padding:5px 0;"><span>最大值：</span>' +
              params.data[5].toFixed(2) +
              '</div>'
            dom +=
              '<div style="display:flex; justify-content: space-between; padding:5px 0;"><span>上四分位数：</span>' +
              params.data[4].toFixed(2) +
              '</div>'
            dom +=
              '<div style="display:flex; justify-content: space-between; padding:5px 0;"><span>中位数：</span>' +
              params.data[3].toFixed(2) +
              '</div>'
            dom +=
              '<div style="display:flex; justify-content: space-between; padding:5px 0;"><span>下四分位数：</span>' +
              params.data[2].toFixed(2) +
              '</div>'
            dom +=
              '<div style="display:flex; justify-content: space-between; padding:5px 0;"><span>最小值：</span>' +
              params.data[1].toFixed(2) +
              '</div>'
            dom += '</div>'
            return dom
          },
        },
      },
    ],
  }
}

// 仪表盘，百分比环形样式
export const getGaugeOptions = (color: string, data: any[]) => {
  return {
    color: [color || '#005AD4'],
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        //当前进度
        progress: {
          show: true,
          roundCap: true,
          width: 8,
          overlap: false, //多组数据时进度条是否重叠
          clip: false, //是否裁掉超出部分
        },
        pointer: {
          show: false,
        },
        //仪表盘轴线相关配置
        axisLine: {
          lineStyle: {
            width: 8,
            // color: [[1, '#07265c']],
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        title: {
          fontSize: 20,
          fontWeight: 'bold',
          color: '#409DEA',
        },
        data,
      },
    ],
  }
}

// 柱形图
export const getBarOptions = (name?: string) => {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    // legend: {
    //   show: true,
    // },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        interval: 0,
      },
      data: [],
    },
    yAxis: {
      name: name ?? '',
      type: 'value',
      min: 'dataMin',
      max: 'dataMax',
    },
    series: [
      {
        type: 'bar',
        barWidth: 30,
        itemStyle: {
          color: '#1f77b4',
        },
        emphasis: {
          focus: 'series',
        },
        label: {
          show: true,
          position: 'top',
        },
        data: [] as any[],
      },
    ],
  }
}

import { defineStore } from 'pinia'
import { constantRoutes, asyncRoutes, whiteNameList } from '@/router'
import { RouteRecordRaw } from 'vue-router'
import { cloneDeep } from 'lodash-es'
import { deepTraversal } from '@/utils'

interface PermissionState {
  routers: RouteRecordRaw[]
  cachedViews?: string[]
}

// 判断是否有给定路由的权限
const hasPermission = (roles: string[], route: RouteRecordRaw) => {
  if (route.meta && route.meta.roles) {
    if (roles.includes('ROOT')) {
      return true
    }
    return roles.some(role => {
      if (route.meta?.roles !== undefined) {
        return (route.meta.roles as string[]).includes(role)
      }
    })
  }
  return false
}

// 根据用户roles和所有异步路由列表生成当前用户异步路由列表
const filterAsyncRoutes = (routes: RouteRecordRaw[], roles: string[]) => {
  const asyncRoutes: RouteRecordRaw[] = []

  routes.forEach(route => {
    const tmpRoute = { ...route }
    if (hasPermission(roles, tmpRoute)) {
      if (tmpRoute.children) {
        tmpRoute.children = filterAsyncRoutes(tmpRoute.children, roles)
      }
      asyncRoutes.push(tmpRoute)
    }
  })
  return asyncRoutes
}

export const usePermissionStore = defineStore('permission', {
  state: (): PermissionState => {
    return {
      routers: [], //全部路由
      cachedViews: [], //需要keep-alive缓存的路由
    }
  },
  actions: {
    // 根据用户角色生成动态路由
    generateRoutes(rolesList: string[]) {
      return new Promise<RouteRecordRaw[]>(resolve => {
        let addRouters: RouteRecordRaw[] = []
        if (rolesList.indexOf('admin') != -1) {
          addRouters = cloneDeep(asyncRoutes)
        } else {
          addRouters = filterAsyncRoutes(cloneDeep(asyncRoutes), rolesList)
        }
        this.routers = [...cloneDeep(constantRoutes), ...addRouters]
        resolve(addRouters)

        this.generateCacheViews()
      })
    },
    // 根据 this.routers 生成 keep-alive 组件名列表，在router.ts中配置keepAlive属性控制是否缓存
    generateCacheViews() {
      if (this.routers.length) {
        const routeList = deepTraversal(this.routers) //深度遍历获取所有嵌套的子路由
        const keepAliveList = routeList.filter((item: RouteRecordRaw) => item?.meta?.keepAlive === true && item.name)
        if (keepAliveList.length) {
          this.cachedViews = keepAliveList.map((item: RouteRecordRaw) => item.name as string)
        }
        // console.log('generateCacheViews---', this.cachedViews)
      }
    },
    // 登出清空permissionStore.routers
    clearPermission() {
      this.routers = []
      this.cachedViews = []
    },
    // 登出删除已挂载的异步路由
    resetRouter(router: any) {
      router.getRoutes().forEach((route: any) => {
        const { name } = route
        if (name && !whiteNameList.includes(name as string)) {
          router.hasRoute(name) && router.removeRoute(name)
        }
      })
    },
  },
})

import { ElNotification } from 'element-plus'
import { createWebsocket } from '@/utils/websocket'

// 订阅当前登录用户工单通知，对应工单受理人
let workOrderWS: any = undefined
let preUserCode = ''
const topic = '/work-order/assign/'
export const useWorkOrderWebsocket = (userCode: string) => {
  console.log('useWorkOrderWebsocket---')
  if (!userCode) {
    return
  }

  /* 
    刷新页面重新执行全局代码，已创建的websocket自动关闭，
    未刷新页面退出登录 + 使用新账号登录，执行 usetCloseWorkOrderWebsocket，关闭上一个workOrderWS，创建新账号的 workOrderWS
    usetCloseWorkOrderWebsocket 放在store/base.ts clearUserInfo 报错 TODO  
  */
  if (preUserCode && userCode !== preUserCode) {
    usetCloseWorkOrderWebsocket()
  }
  preUserCode = userCode

  if (!workOrderWS) {
    workOrderWS = createWebsocket(
      'wss://api.gotiondigitalpower.com/api/ess/messaging',
      { id: 'work-order-assign', topic: topic + userCode, type: 'sub' },
      (data: any) => {
        console.log('successCB', data)
        try {
          if (data?.type === 'result') {
            if (data?.topic === topic + userCode) {
              let payload = data?.payload
              if (payload) {
                payload = JSON.parse(payload)
              }
              const workOrderNo = payload?.workOrderNo
              if (workOrderNo) {
                ElNotification({
                  title: '工单通知',
                  // message: '你有工单：' + workOrderNo + '待处理',
                  dangerouslyUseHTMLString: true,
                  message:
                    '<a  href=' +
                    location.origin +
                    '/#/workOrder/detail?id=' +
                    workOrderNo +
                    ' >你有工单 <span style="color: #409eff">' +
                    workOrderNo +
                    '</span> 待处理</a>',
                  duration: 0,
                })
              }
            }
          }
        } catch (error) {
          console.log(error)
        }
      },
      (error: any) => {
        // console.log('errorCB', error)
      },
    )
  }
}

export const usetCloseWorkOrderWebsocket = () => {
  console.log('usetCloseWorkOrderWebsocket---')
  workOrderWS && workOrderWS.closeWebsocket?.()
  workOrderWS = undefined
}

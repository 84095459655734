<template>
  <div class="main-page">
    <el-container class="main-content">
      <el-aside :width="isCollapse ? '60px' : '210px'">
        <nav-menu :isCollapse="isCollapse" mode="vertical"></nav-menu>
      </el-aside>
      <el-container class="page">
        <el-header class="page-header">
          <el-icon :size="20" style="margin-right: 10px" @click="handleChangeCollapse">
            <Expand v-if="isCollapse" />
            <Fold v-else />
          </el-icon>
          <div class="breadcrumb">
            <span
              :class="['breadcrumb-item', index == breadCrumb.length - 1 ? 'active' : '']"
              v-for="(item, index) in breadCrumb"
              :key="index"
              ><span v-if="index != 0 && !!item ? ' / ' : ''" class="breadcrumb-separator">/</span> {{ item }}</span
            >
          </div>
          <div class="header-right">
            <el-button text :icon="DataAnalysis" @click="toBigScreen">大屏</el-button>
            <el-dropdown>
              <div class="user-info">
                <el-avatar :size="26" :icon="UserFilled" />
                <span class="name" v-if="username">{{ username }}</span>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="handleQuit">退出系统</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-header>
        <el-main class="page-content">
          <router-view v-slot="{ Component, route }">
            <keep-alive :include="cachedViews">
              <component :is="Component" :key="route.fullPath" />
            </keep-alive>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import NavMenu from './NavMenu.vue'
import { Expand, Fold, DataAnalysis, UserFilled } from '@element-plus/icons-vue'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/base'
import { usePermissionStore } from '@/store/permission'
import pinia from '@/store'

// const route = useRoute()
// let matched = route.matched.filter(item => item.meta && item.meta.title)
// console.log(matched)

let appStore = useAppStore(pinia)
const isCollapse = ref(false)
const handleChangeCollapse = () => {
  isCollapse.value = !isCollapse.value
  appStore.toggleIsCollapse(isCollapse.value)
}

const router = useRouter()
let breadCrumb = ref<any[]>([])
let arr = router.currentRoute.value.matched.map(item => item.meta.title)
breadCrumb.value = [...new Set(arr)]

watch(
  () => router.currentRoute.value,
  (newValue, oldValue) => {
    // console.log('watch-router', newValue, oldValue)
    let arr = newValue.matched.map(item => item.meta.title)
    breadCrumb.value = [...new Set(arr)]
  },
  {
    immediate: true,
  },
)

// 跳转大屏
const toBigScreen = () => {
  router.push('/energy-storage')
}

// 用户信息
let userStore = useUserStore(pinia)
let permissionStore = usePermissionStore(pinia)
let username = userStore.userInfo.username || ref('')

const cachedViews = permissionStore.cachedViews

const handleQuit = () => {
  localStorage.clear()
  userStore.clearUserInfo()
  permissionStore.clearPermission()
  permissionStore.resetRouter(router)
  router.replace('/login')
}
</script>

<style scoped lang="less">
.main-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-content,
.page {
  height: 100%;
}

.page-header {
  > .el-icon {
    cursor: pointer;
  }
  .header-right:extend(.flex-vcenter) {
    position: absolute;
    top: 8px;
    right: 20px;
    font-size: 14px;
    .user-info:extend(.flex-vhcenter) {
      margin-left: 15px;
      cursor: pointer;
      .name {
        margin-left: 5px;
      }
    }
  }
}
.breadcrumb {
  .breadcrumb-item {
    color: rgba(0, 0, 0, 0.45);
    &.active {
      color: rgba(0, 0, 0, 0.85);
    }
    .breadcrumb-separator {
      padding: 0 8px;
    }
  }
}
.page-content {
  height: calc(100% - 48px);

  .page-info {
    background-color: #fff;
    border-radius: 5px;
  }
}

.el-header,
.el-footer {
  display: flex;
  color: #333;
  text-align: center;
  align-items: center;
}

.el-header {
  height: 48px !important;
}

.el-aside {
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 200px;
  text-align: left;
  cursor: pointer;
  background-color: #fff;
  transition: width 0.3s linear;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    display: none;
  }
}

.el-main {
  color: #333;
  text-align: left;
  background-color: #f0f2f5;
}
</style>

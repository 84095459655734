/*
 * 已实现：请求拦截；结合vue.config.js多个baseUrl代理配置；登录失效统一弹出登录弹框 + 登录弹框弹出次数控制；不需要携带token的请求实例；无感知刷新token
 * TODO：请求报错取消后续请求
 */

import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '../router'
import { useUserStore } from '@/store/base'
import { usePageStore } from '@/store/page'
import pinia from '@/store'
import { useRefreshToken } from '@/hooks/token'

const userStore = useUserStore(pinia) //hideTokenExpiredPop：token失效时，页面如果连续发出了多个请求，避免弹出多个登录提示弹框。初始化false，允许弹出。
const pageStore = usePageStore(pinia) //expiredUrl：token失效时，记录失效的页面url，登录后重新跳转进入

const tokenUrl = 'api/uaa/oauth/token' //登录、刷新token的接口url

// 创建axios实例，开发环境：vue.config.js 可根据baseURL匹配多个代理。生产环境：后端接口和前端不在一个域名时，需要后端或运维处理跨域问题
const getInstance = function (customeDomin?: string) {
  let baseURL = ''
  if (process.env.VUE_APP_ENV === 'development') {
    baseURL = customeDomin || '/apis'
  } else {
    baseURL = customeDomin == 'xx' ? process.env.VUE_APP_BASEURL_XX! : process.env.VUE_APP_BASEURL!
  }
  return axios.create({
    baseURL,
    timeout: 60000, // 请求超时时间
  })
}
const services = []
const baseService = getInstance() //默认域名，vue.config.js 根据apis做代理
const xxService = getInstance('xx') //xx接口域名，vue.config.js 根据xx做代理
const bdService = getInstance('baiduAPI')
const noTokenService = getInstance() //不需要携带token的实例，其他同 baseService
services.push(baseService)
services.push(xxService)
services.push(bdService)
services.push(noTokenService)

// token失效时，记录当前页面url
const recordUrl = () => {
  let url = window.location.href.split('#')[1] || ''
  // 跳转进入登录页面期间的请求失效弹框，不记录登录页面url
  if (url != '/login') {
    pageStore.setExpiredUrl(url) //记录登录失效的url，登录后重新跳转进入
  }
}

// token失效时跳转登录页面的处理，记录当前页面url，弹出提示弹框
const handleReLogin = () => {
  recordUrl()
  userStore.setHideTokenExpiredPop(true) //禁止登录失效弹框再次弹出
  ElMessageBox.confirm('登录失效，请重新登录', '系统提示')
    .then(() => {
      userStore.clearUserInfo() //清除缓存信息，避免被src/permission拦截，跳转登录页面
      router.replace('/login') //跳转登录页面
      userStore.setHideTokenExpiredPop(false) //点击确定，允许登录失效弹框再次弹出
    })
    .catch(() => {
      userStore.setHideTokenExpiredPop(false) //点击取消，允许登录失效弹框再次弹出
    })
}

// 更新请求头里的 Authorization
const setToken = (config: any) => {
  // pinia数据需在setup中或之后生命周期使用
  let tokenType = userStore.userInfo?.tokenInfo?.token_type
  let accessToken = userStore.userInfo?.tokenInfo?.access_token
  if (accessToken && tokenType) {
    config.headers.Authorization = tokenType[0].toUpperCase() + tokenType.slice(1) + ' ' + accessToken
  }
}

// token失效时刷新token的处理，记录当前页面url，刷新token请求，阻塞并记录刷新token期间的请求，再次发起请求
let isRefreshing = false //正在刷新token
let retryRequestList: any[] = [] //token失效期间发起的需要在刷新token后再次发起的请求
const handleRefreshToken = async (service: any, config: any) => {
  // recordUrl()
  if (!userStore?.userInfo?.tokenInfo?.refresh_token) {
    return
  }
  setToken(config)
  if (!isRefreshing) {
    // console.log('----- refresh_token1')
    isRefreshing = true
    return await useRefreshToken()
      .then(() => {
        // console.log('refresh_token_then ---')
        //此处处于当前请求后面的请求在刷新token后首先发出，默认请求之间无继发关系，有继发关系的请求的发出时机默认由各个页面控制
        retryRequestList.map(cb => cb())
        retryRequestList = []
        return service(config) //最后再次发起当前请求，并返回执行结果，由于刷新token期间并未return，发起该请求的页面处理请求pending状态，页面后续then处理未丢失
      })
      .catch(error => {
        // console.log('refresh_token_catch ---', error)
        return Promise.reject(error) //继续抛出错误，最终传递到页面对应catch
      })
      .finally(() => {
        // console.log('refresh_token_finally ---')
        isRefreshing = false
      })
  } else {
    // console.log('----- refresh_token2')
    // 阻塞并记录刷新token期间发起的请求，待刷新token成功后再执行：此处返回Promise，request.ts请求拦截 useRefreshToken 给页面返回相应的Promise，页面处于pending状态。记忆发起请求的位置，对应位置的请求在无感知刷新期间then回调处理也被保留
    return new Promise((resolve, reject) => {
      retryRequestList.push(() => {
        resolve(service(config))
      })
    })
  }
}

// 添加请求拦截、响应拦截
services.map((service, index) => {
  service.interceptors.request.use(
    (config: any) => {
      // console.log('request-interceptors:', config)

      // 默认 services 最后一项 noTokenService 是不需要携带token的请求实例
      if (index != services.length - 1) {
        setToken(config)
      }

      config.headers.source = 'gotion'
      return config
    },
    (error: any) => {
      Promise.reject(error)
    },
  )
  service.interceptors.response.use(
    async (response: AxiosResponse) => {
      // console.log('request-response:', response)
      const hideInterceptorsAlert: boolean = response?.config?.headers?.hideInterceptorsAlert ? true : false //是否隐藏全局请求拦截提示

      //登录接口处理：任何错误码统一跳转登录页面
      if (response.config.url === tokenUrl) {
        if (response.status === 200) {
          return Promise.resolve(response)
        } else {
          // 登录页面不允许弹出弹框，其他页面弹出弹框跳转登录
          if (response.data?.error_description) {
            ElMessage.error(response.data?.error_description)
          }
          const isInLoginPage = location.hash.indexOf('login') != -1
          if (!userStore.hideTokenExpiredPop && !isInLoginPage) {
            handleReLogin()
          }
          return Promise.reject(response)
        }
      }

      const res = response.data
      if (res.status === 200) {
        return Promise.resolve(res)
      } else if (res.status === 401) {
        // 刷新token。刷新token接口调用一次，如果报错则跳转登录页面调登录接口
        return handleRefreshToken(service, response.config)
      } else {
        !hideInterceptorsAlert && ElMessageBox.alert(res.message || '服务器异常，请联系管理员', '系统提示')
        return Promise.reject(res.message)
      }
    },
    async (error: any) => {
      console.log('request-error-----', error)

      const hideInterceptorsAlert: boolean = error?.config?.headers?.hideInterceptorsAlert ? true : false //是否隐藏全局请求拦截提示

      //登录接口处理：任何错误码统一跳转登录页面
      if (error?.config.url === tokenUrl) {
        // 登录页面不允许弹出弹框，其他页面弹出弹框跳转登录
        if (error.response?.data?.error_description) {
          ElMessage.error(error.response?.data?.error_description)
        }
        const isInLoginPage = location.hash.indexOf('login') != -1
        if (!userStore.hideTokenExpiredPop && !isInLoginPage) {
          handleReLogin()
        }
        return Promise.reject(error)
      }

      if (error?.response?.status === 401) {
        // 刷新token
        return handleRefreshToken(service, error.config)
      } else if (error?.response?.status === 500) {
        // 500 错误，不展示后端返回的信息
        !hideInterceptorsAlert && ElMessageBox.alert('服务器异常，请联系管理员', '系统提示')
        return Promise.reject(error)
      } else {
        if (error?.response?.data?.message) {
          !hideInterceptorsAlert &&
            ElMessageBox.alert(error?.response?.data?.message || '服务器异常，请联系管理员', '系统提示')
        }
        return Promise.reject(error)
      }
    },
  )
})
export default baseService
export { xxService, bdService, noTokenService }

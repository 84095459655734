<template>
  <div :class="'nav-menu-' + mode">
    <div :class="['logo', isCollapse ? 'collapse' : '']">
      <template v-if="mode === 'vertical'">
        <img class="img-left" :src="logoLeft" alt="logo" />
        <img v-if="!isCollapse" class="img-right" :src="logoRight" alt="logo" />
        <!-- 临时代码，区分各个项目，后期删除 TODO -->
        <!-- <span class="text-title">ZTGT</span> -->
      </template>
      <template v-else> <img class="img" :src="logo" alt="logo" /></template>
    </div>
    <el-menu
      class="el-menu-vertical-demo"
      :default-active="defaultActive"
      background-color="#fff"
      text-color="#333"
      active-text-color="#eb853b"
      :collapse="collapse"
      :mode="mode"
    >
      <nav-menu-item
        :mode="mode"
        :collapse="collapse"
        v-for="route in menuList"
        :key="route.path"
        :item="route"
        :base-path="route.path"
      />
    </el-menu>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import pinia from '@/store'
import { usePermissionStore } from '@/store/permission'
import NavMenuItem from './NavMenuItem.vue'

const props = withDefaults(
  defineProps<{
    isCollapse?: boolean //菜单是否折叠，vertical模式有效
    mode: string
  }>(),
  {
    isCollapse: false,
    mode: 'vertical',
  },
)

let collapse = computed({
  get: () => (props.mode === 'vertical' ? props.isCollapse : undefined),
  set: val => {},
})

let logoLeft = require('@/assets/img/Gotion1.png')
let logoRight = require('@/assets/img/Gotion2.png')
let logo = require('@/assets/img/Gotion.png')

const permissionStore = usePermissionStore(pinia)
let menuList = permissionStore.routers

const router = useRouter()

watch(
  () => router.currentRoute.value,
  (newValue, oldValue) => {
    // console.log(newValue, oldValue)
    defaultActive.value = newValue.path
  },
)

let defaultActive = ref('/device')
onBeforeMount(() => {
  defaultActive.value = router.currentRoute.value.path
})
</script>

<style scoped lang="less">
.nav-menu-vertical {
  height: 100%;
  .logo {
    position: relative;
    width: 100%;
    height: 48px;
    line-height: 48px;
    overflow: hidden;
    text-align: left;
    padding-left: 15px;

    .img-left {
      width: 38px;
      height: 38px;
      vertical-align: middle;
    }
    .img-right {
      width: 76px;
      height: 38px;
      vertical-align: middle;
    }
    .title {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }
    .text-title {
      padding-left: 8px;
      color: #e8e8e8;
    }
  }
  .el-menu {
    border-right: none;
  }
  .collapse.logo {
    padding-left: 11px;
  }
}
.nav-menu-horizontal {
  display: flex;
  width: 100%;
  height: 100%;
  .logo {
    position: relative;
    width: 210px;
    height: 70px;
    display: flex;
    align-items: center;
    .img {
      width: 60%;
      height: auto;
    }
  }
  .el-menu {
    border-bottom: none;
    flex: 1;
  }
}
:deep .el-menu.el-menu--collapse .el-sub-menu__title > span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
:deep .el-menu.el-menu--collapse .el-sub-menu__title .el-sub-menu__icon-arrow {
  display: none;
}
</style>

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import WebLayout from '../views/layout/webLayout.vue' //左侧导航
// import WebLayout from '../views/layout/webLayout2.vue' //头部导航

/*
meta:{
  title:string        必填，页面名称，显示在标题栏
  roles:string[]      必填，可访问页面的权限列表。暂时设为['admin']，登录页面给所有用户设置了'admin'权限 TODO
  hidden?:boolean     可选，是否隐藏导航，通常：详情页面传参进入不显示在导航列表
  icon?:string        可选，使用左侧导航时，显示的导航图标。头部导航默认隐藏图标。使用 components/SvgIcon组件 + iconfont图标库项目
  alwaysShow?:boolean 可选，是否显示子导航，当只有一个 hidden不为true 的子元素时，设置是否渲染子导航。alwaysShow:true 渲染 el-sub-menu，否则渲染 el-menu
  keepAlive?:boolean  可选，是否缓存页面
}
*/

//路由白名单维护
export const whiteNameList = ['WebLogin', 'Root', 'NotFound', 'ChangePassword', 'RegisterPage']
export const whitePathList = ['/login', '/404', '/change', '/register', '/webview']

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'WebLogin',
    component: () => import('../views/webLogin.vue'),
    meta: { title: '登录', hidden: true },
  },
  {
    path: '/change',
    name: 'ChangePassword',
    component: () => import('../views/logPassword/change-password.vue'),
    meta: { title: '修改密码', hidden: true },
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: () => import('../views/logPassword/register-page.vue'),
    meta: { title: '注册', hidden: true },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: { title: '404', hidden: true },
  },
  {
    path: '/webview',
    name: 'WebView',
    component: () => import('../views/webview.vue'),
    meta: { title: 'webview', hidden: true },
  },
  {
    path: '/',
    redirect: '/home/index',
    name: 'Root',
    meta: { hidden: true },
  },
]

export const asyncRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'Home',
    component: WebLayout,
    redirect: '/home/index',
    meta: { title: '首页', icon: 'icon-shouye1', roles: ['admin'] },
    children: [
      {
        path: 'index',
        name: 'Home',
        component: () => import('../views/Home/HomeView.vue'),
        meta: { title: '首页', icon: 'icon-shouye1', roles: ['admin'] },
      },
    ],
  },
  {
    path: '/energyStorage',
    name: 'EnergyStorage',
    component: WebLayout,
    meta: { title: '储能站', icon: 'icon-chunengxitong', roles: ['admin'] },
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: () => import('../views/EnergyStorageSilo/OverView.vue'),
        meta: { title: '概览', roles: ['admin'] },
      },
      {
        path: 'silo',
        name: 'Silo',
        meta: { title: '储能舱', roles: ['admin'] },
        redirect: '/energyStorage/silo/silo1',
        children: [
          // {
          //   path: 'silo1',
          //   name: 'Silo1',
          //   component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
          //   meta: { title: '一舱', roles: ['admin'] },
          // },
          // {
          //   path: 'silo2',
          //   name: 'Silo2',
          //   component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
          //   meta: { title: '二舱', roles: ['admin'] },
          // },
          // {
          //   path: 'silo3',
          //   name: 'Silo3',
          //   component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
          //   meta: { title: '三舱', roles: ['admin'] },
          // },
          // {
          //   path: 'silo4',
          //   name: 'Silo4',
          //   component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
          //   meta: { title: '四舱', roles: ['admin'] },
          // },
          {
            path: 'silo5',
            name: 'Silo5',
            component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
            meta: { title: '五舱', roles: ['admin'] },
          },
          {
            path: 'silo6',
            name: 'Silo6',
            component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
            meta: { title: '六舱', roles: ['admin'] },
          },
          {
            path: 'silo7',
            name: 'Silo7',
            component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
            meta: { title: '七舱', roles: ['admin'] },
          },
          {
            path: 'silo8',
            name: 'Silo8',
            component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
            meta: { title: '八舱', roles: ['admin'] },
          },
          {
            path: 'silo9',
            name: 'Silo9',
            component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
            meta: { title: '九舱', roles: ['admin'] },
          },
          {
            path: 'silo10',
            name: 'Silo10',
            component: () => import('../views/EnergyStorageSilo/EnergyStorageSilo.vue'),
            meta: { title: '十舱', roles: ['admin'] },
          },
          {
            path: 'details',
            name: 'Details',
            component: () => import('../views/EnergyStorageSilo/EnergyStorageDetaills.vue'),
            meta: { title: '电池簇', roles: ['admin'], hidden: true },
          },
        ],
      },
    ],
  },
  {
    path: '/alarmList',
    name: 'AlarmList',
    component: WebLayout,
    redirect: '/alarmList/index',
    meta: { title: '报警界面', icon: 'icon-gaojing', roles: ['admin'] },
    children: [
      {
        path: 'index',
        name: 'AlarmList',
        component: () => import('../views/Alarm/AlarmList.vue'),
        meta: { title: '报警界面', icon: 'icon-gaojing', roles: ['admin'] },
      },
    ],
  },
  {
    path: '/dataAnalysis',
    name: 'DataAnalysis',
    component: WebLayout,
    meta: { title: '数据分析', icon: 'icon-tubiao-bingtu', roles: ['admin'] },
    children: [
      {
        path: 'index',
        name: 'DataAnalysis',
        component: () => import('../views/DataAnalysis/Index.vue'),
        meta: { title: '数据分析', icon: 'icon-tubiao-bingtu', roles: ['admin'] },
      },
    ],
  },

  {
    path: '/energy-storage',
    name: 'EnergyStoragePowerStation',
    component: () => import('../views/BigScreen/EnergyStoragePowerStation.vue'),
    meta: { title: '储能电站', hidden: true, roles: ['admin'] },
  },
  {
    path: '/runningAnalysis',
    name: 'RunningAnalysis',
    component: WebLayout,
    meta: { title: '运营分析', icon: 'icon-yunyingguanli1', roles: ['admin'], en: { title: 'Operation Management' } },
    children: [
      {
        path: 'index',
        name: 'RunningAnalysis',
        component: () => import('../views/RunningAnalysis/RunningAnalysis.vue'),
        meta: {
          title: '运营分析',
          icon: 'icon-yunyingguanli1',
          roles: ['admin'],
          keepAlive: true,
          en: { title: 'Operation Management' },
        },
      },
    ],
  },

  {
    path: '/workOrder',
    component: WebLayout,
    redirect: '/workOrder/list',
    name: 'WorkOrder',
    meta: { title: '工单', icon: 'icon-gongdan', roles: ['admin'], en: { title: 'Ticket System' } },
    children: [
      {
        path: 'overview',
        name: 'WorkOrderOverview',
        component: () => import('../views/WorkOrder/WorkOrderOverview.vue'),
        meta: { title: '概览', icon: 'icon-gailan', roles: ['admin'], en: { title: 'Overview' } },
      },
      {
        path: 'list',
        name: 'WorkOrderList',
        component: () => import('../views/WorkOrder/WorkOrderList.vue'),
        meta: {
          title: '工单列表',
          icon: 'icon-gongdan',
          roles: ['admin'],
          keepAlive: true,
          en: { title: 'Order List' },
        },
      },
      {
        path: 'detail',
        name: 'WorkOrderDetail',
        component: () => import('../views/WorkOrder/WorkOrderDetail.vue'),
        meta: { title: '工单详情', hidden: true, en: { title: 'Order Details' } },
      },
      {
        path: 'case',
        name: 'CaseList',
        component: () => import('../views/WorkOrder/CaseList.vue'),
        meta: { title: '案例库', icon: 'icon-a-ziyuan652', roles: ['admin'], en: { title: 'Case List' } },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...constantRoutes],
})

export default router

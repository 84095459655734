import request, { bdService, noTokenService } from '@/utils/request'

// 登录
export function oauthToken(data: any) {
  // return request({
  //   url: 'uaa/oauth/token',
  //   method: 'post',
  //   data,
  //   // headers: { 'content-type': 'application/x-www-form-urlencoded' },
  // })
  return noTokenService({
    url: 'api/uaa/oauth/token',
    method: 'post',
    data,
  })
}

// 字典
export function dictionaryID(data: any) {
  return request({
    url: `api/ess/dictionary/${data.id}/items`,
    method: 'get',
  })
}

// 发送短信 {code, smsType:[找回密码RETRIEVE_PASSWOR, 注册REGISTER, 登录LOGIN_SMS] }
export function smsCode(data: any) {
  return request({
    url: `api/ess/user/openApi/sms/code`,
    method: 'post',
    data,
  })
}

// 更改密码
export function updatePassword(data: any) {
  return request({
    url: `api/ess/user/openApi/update/password`,
    method: 'post',
    data,
  })
}

// 注册提交审核
export function registration(data: any) {
  return request({
    url: `api/ess/user/openApi/registration/commit`,
    method: 'post',
    data,
  })
}

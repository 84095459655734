import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = { class: "main-page" }
const _hoisted_2 = { class: "breadcrumb" }
const _hoisted_3 = {
  key: 0,
  class: "breadcrumb-separator"
}
const _hoisted_4 = { class: "header-right" }
const _hoisted_5 = { class: "user-info" }
const _hoisted_6 = {
  key: 0,
  class: "name"
}

import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import NavMenu from './NavMenu.vue'
import { Expand, Fold, DataAnalysis, UserFilled } from '@element-plus/icons-vue'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/base'
import { usePermissionStore } from '@/store/permission'
import pinia from '@/store'

// const route = useRoute()
// let matched = route.matched.filter(item => item.meta && item.meta.title)
// console.log(matched)


export default /*@__PURE__*/_defineComponent({
  __name: 'webLayout',
  setup(__props) {

let appStore = useAppStore(pinia)
const isCollapse = ref(false)
const handleChangeCollapse = () => {
  isCollapse.value = !isCollapse.value
  appStore.toggleIsCollapse(isCollapse.value)
}

const router = useRouter()
let breadCrumb = ref<any[]>([])
let arr = router.currentRoute.value.matched.map(item => item.meta.title)
breadCrumb.value = [...new Set(arr)]

watch(
  () => router.currentRoute.value,
  (newValue, oldValue) => {
    // console.log('watch-router', newValue, oldValue)
    let arr = newValue.matched.map(item => item.meta.title)
    breadCrumb.value = [...new Set(arr)]
  },
  {
    immediate: true,
  },
)

// 跳转大屏
const toBigScreen = () => {
  router.push('/energy-storage')
}

// 用户信息
let userStore = useUserStore(pinia)
let permissionStore = usePermissionStore(pinia)
let username = userStore.userInfo.username || ref('')

const cachedViews = permissionStore.cachedViews

const handleQuit = () => {
  localStorage.clear()
  userStore.clearUserInfo()
  permissionStore.clearPermission()
  permissionStore.resetRouter(router)
  router.replace('/login')
}

return (_ctx: any,_cache: any) => {
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, { class: "main-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, {
          width: isCollapse.value ? '60px' : '210px'
        }, {
          default: _withCtx(() => [
            _createVNode(NavMenu, {
              isCollapse: isCollapse.value,
              mode: "vertical"
            }, null, 8, ["isCollapse"])
          ]),
          _: 1
        }, 8, ["width"]),
        _createVNode(_component_el_container, { class: "page" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, { class: "page-header" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  size: 20,
                  style: {"margin-right":"10px"},
                  onClick: handleChangeCollapse
                }, {
                  default: _withCtx(() => [
                    (isCollapse.value)
                      ? (_openBlock(), _createBlock(_unref(Expand), { key: 0 }))
                      : (_openBlock(), _createBlock(_unref(Fold), { key: 1 }))
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(breadCrumb), (item, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                      class: _normalizeClass(['breadcrumb-item', index == _unref(breadCrumb).length - 1 ? 'active' : '']),
                      key: index
                    }, [
                      (index != 0 && !!item ? ' / ' : '')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "/"))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(item), 1)
                    ], 2))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_button, {
                    text: "",
                    icon: _unref(DataAnalysis),
                    onClick: toBigScreen
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("大屏")
                    ])),
                    _: 1
                  }, 8, ["icon"]),
                  _createVNode(_component_el_dropdown, null, {
                    dropdown: _withCtx(() => [
                      _createVNode(_component_el_dropdown_menu, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_dropdown_item, { onClick: handleQuit }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createTextVNode("退出系统")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_el_avatar, {
                          size: 26,
                          icon: _unref(UserFilled)
                        }, null, 8, ["icon"]),
                        (_unref(username))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(username)), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, { class: "page-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view, null, {
                  default: _withCtx(({ Component, route }) => [
                    (_openBlock(), _createBlock(_KeepAlive, { include: _unref(cachedViews) }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        key: route.fullPath
                      }))
                    ], 1032, ["include"]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})
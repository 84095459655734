<!-- 组件设计：CommonSearch查询处理handleQuery放在父组件页面；
  CommonPagination数据pageData:{pageNum,pageSize,totalCount}在页面内使用hooks统一生成，可供CommonSearch、CommonPagination修改使用；
  CommonTable只做展示，不操作其他组件数据 -->
<template>
  <div class="common-search-component">
    <el-form :model="formData" :inline="true" :label-position="props.labelPosition">
      <el-row>
        <el-col
          :span="item.elColSpan || props.elColSpan || 6"
          class="search-form-item"
          v-for="item in propsList"
          :key="item.prop"
        >
          <el-form-item
            class="item-style"
            :label-width="props.labelWidth"
            :label="props.showLable ? item.label : ''"
            :prop="item.prop"
          >
            <template #label v-if="item.iconName">
              <span>
                <svg-icon :iconName="item.iconName" :size="18" color="#606266" />
                {{ props.showLable ? item.label : '' }}
              </span>
            </template>

            <slot v-if="item.slotName" :name="item.slotName" :prop="item"></slot>
            <template v-else>
              <!-- 嵌套的ref不会解包 -->

              <!-- 10 输入框 -->
              <el-input
                v-if="item.type == '10'"
                v-model="formData[item.prop].value"
                :placeholder="'请输入' + item.label"
                :clearable="'clearable' in item ? !!item.clearable : true"
                :disabled="'disabled' in item ? !!item.disabled : false"
              ></el-input>

              <!-- 20 日期区间选择框 -->
              <el-date-picker
                v-if="item.type == '20'"
                type="daterange"
                v-model="formData[item.prop].value"
                value-format="YYYY-MM-DD"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="'clearable' in item ? !!item.clearable : true"
                :disabled="'disabled' in item ? !!item.disabled : false"
              ></el-date-picker>
              <!-- 21 日期时间区间选择框， HH:mm:ss 24时制，默认时刻从00:00:00开始，hh:mm:ss 12时制，默认时刻从12:00:00开始，-->
              <el-date-picker
                v-if="item.type == '21'"
                type="datetimerange"
                v-model="formData[item.prop].value"
                value-format="YYYY-MM-DD HH:mm:ss"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :shortcuts="item.shortcuts"
                :disabled-date="item.disabledDate"
                :clearable="'clearable' in item ? !!item.clearable : true"
                :disabled="'disabled' in item ? !!item.disabled : false"
              ></el-date-picker>

              <!-- 30 下拉选择框 -->
              <el-select
                v-if="item.type == '30'"
                v-model="formData[item.prop].value"
                :placeholder="'请选择' + item.label"
                :clearable="'clearable' in item ? !!item.clearable : true"
                :disabled="'disabled' in item ? !!item.disabled : false"
                :filterable="'filterable' in item ? !!item.filterable : false"
                :multiple="'multiple' in item ? !!item.multiple : false"
                :collapse-tags="true"
              >
                <!--  el-select 选项值集，默认数据结构[{label:'',value:''}] -->
                <el-option
                  v-for="(optionItem, optionItemIndex) in item.options"
                  :key="optionItemIndex"
                  :label="optionItem[item?.optionItemKeysMap?.label || 'label']"
                  :value="optionItem[item?.optionItemKeysMap?.value || 'value']"
                ></el-option>
              </el-select>

              <!-- 40 级联选择器 -->
              <el-cascader
                v-if="item.type == '40'"
                v-model="formData[item.prop].value"
                :options="item.options"
                :props="item.cascaderProps"
                :placeholder="'请选择' + item.label"
                :clearable="'clearable' in item ? !!item.clearable : true"
                :disabled="'disabled' in item ? !!item.disabled : false"
                :collapse-tags="item.collapseTags"
                @change="handleChangeCascader"
              >
              </el-cascader>

              <!-- 50 自动补全输入框 -->
              <el-autocomplete
                v-if="item.type == '50'"
                v-model="formData[item.prop].value"
                :fetch-suggestions="item.queryFetch"
                :placeholder="'请选择' + item.label"
                :clearable="'clearable' in item ? !!item.clearable : true"
                :disabled="'disabled' in item ? !!item.disabled : false"
              ></el-autocomplete>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="propsList.length <= 2 ? 8 : 4" class="search-form-item">
          <el-form-item>
            <el-button v-if="showRest" size="default" @click="handleEmit('resetAndQuery')">重置</el-button>
            <el-button type="primary" size="default" @click="handleEmit('query')">查询</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, toRefs, onBeforeMount, watch } from 'vue'
import { BaseObject } from '@/types/index'
import { dictionaryID } from '@/api/common'

// el-select options 选项类型
interface OptionItem {
  label?: string
  value?: any
  [index: string]: any
}
// el-option label/value 使用其他字段名的映射
interface OptionItemKeysMap {
  label: string
  value: string
}

interface CascaderProps {
  checkStrictly?: boolean
  value?: string
  label?: string
  lazy?: boolean
  lazyLoad?: (node: any, resolve: any) => void
  [index: string]: any
}

// el-form-item 数据类型
interface SearchItem {
  type: '10' | '20' | '21' | '30' | '40' | '50' | '60' // 10:el-input, 20:el-date-picker, 30:el-select,  40:el-cascader,  50:el-autocomplete,
  label: string // 表单项 label
  prop: string // 表单项 prop
  clearable?: boolean // 表单项 是否可清除，默认 true
  options?: OptionItem[] // 表单项是el-select且选项是值集时，查询到的选项列表，接口数据结构 [{value:'', name:''}]
  valueSetCode?: string // 表单项是el-select且选项是值集时，查询值集的code
  optionItemKeysMap?: OptionItemKeysMap // 表单项是el-select且选项非值集时，<el-option>使用的属性的映射
  slotName?: string // 插槽名，有值即表示使用插槽
  disabled?: boolean // 是否禁用
  elColSpan?: number // el-form-item :span的值
  cascaderProps?: CascaderProps // 表单项是el-cascader时，props
  showAllLevels?: boolean // 表单项是el-cascader时，show-all-levels
  queryFetch?: (queryString: string, cb: any) => void // 表单项是el-autocomplete时，fetch-suggestions
  shortcuts?: any[] //20 21 时间选择框 快捷选项
  disabledDate?: any //20 21 时间选择框 禁止选择的日期
  collapseTags?: boolean
  filterable?: boolean //el-select 的 filterable
  multiple?: boolean //el-select 的 multiple
  iconName?: string //表单项icon，配合<svg-icon>组件
}
// 如何定义PropsValue的对象类型，使包含所有propsList的prop，运行时确定propsList，无法校验？？？TODO
// interface PropsValue<T extends SearchItem> {
//   [p in keyof T]:any
// }
// props的类型声明、默认值。（vue3要求，类型声明参数：类型字面量；在同一文件中的接口或类型字面量的引用）
const props = withDefaults(
  defineProps<{
    propsList: SearchItem[]
    propsValue: BaseObject
    labelWidth?: string
    elColSpan?: number
    showLable?: boolean
    labelPosition?: 'left' | 'right' | 'top'
    showRest?: boolean
  }>(),
  {
    propsList: () => [],
    propsValue: () => ({}),
    labelWidth: 'auto',
    elColSpan: 6,
    showLable: true,
    labelPosition: 'left',
    showRest: true,
  },
)
let formData = toRefs(props.propsValue) //defineProps声明的属性，不可修改，vue已设置为readonly，但是props.key.key可修改。propsValue是在父组件声明的reactive数据，传入子组件保持响应式

const emit = defineEmits(['resetAndQuery', 'query'])
const handleEmit = (type: 'resetAndQuery' | 'query') => {
  //此处 type 不能使用 type:string，emit 第一个参数被vue定义为defineEmits的联合类型
  emit(type)
}

const getOptions = (item: SearchItem) => {
  dictionaryID({ id: item.valueSetCode })
    .then((res: any) => {
      if (res.data && res.data.length) {
        item.options = res.data.map((item: any) => ({ ...item, label: item.text }))
      } else {
        item.options = []
      }
      // el-select先赋值v-modle，后更新el-option，没有显示正确的lable，两次更新v-model触发再次渲染
      let currentValue = props.propsValue[item.prop]
      if (currentValue !== undefined && currentValue !== null) {
        setTimeout(() => {
          formData[item.prop].value = undefined
          formData[item.prop].value = currentValue
        }, 0)
      }
      console.log('dictionaryID', item.valueSetCode, typeof currentValue)
    })
    .catch(error => {
      console.log('CommonSearch-dictionaryID-error', error)
    })
}

watch(
  () => props.propsList,
  newValue => {
    props.propsList.map(item => {
      if (item.valueSetCode) {
        getOptions(item)
      }
    })
  },
)

onBeforeMount(() => {
  // 数据字典：el-select 选项值集，构造数据结构[{label:'',value:''}]。
  props.propsList.map(item => {
    if (item.valueSetCode) {
      getOptions(item)
    }
  })
})

const handleChangeCascader = value => {
  console.log('handleChangeCascader', value)
}
onMounted(() => {})
</script>

<style scoped lang="less">
.common-search-component {
  .search-form-item {
    margin-bottom: 18px;
  }
  :deep .el-form-item {
    margin-bottom: 0;
  }
  :deep .el-input {
    min-width: 150px;
    height: 32px;
  }
  :deep .el-select {
    min-width: 150px;
    height: 32px;
  }
  :deep .el-autocomplete {
    min-width: 150px;
    height: 32px;
  }
  :deep .el-date-editor {
    min-width: 240px;
    height: 32px;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-pagination-component" }

import { toRefs, watch } from 'vue'
interface PageData {
  pageNum: number
  pageSize: number
  totalCount: number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CommonPagination',
  props: {
    pageData: {},
    pageSizes: { default: () => [10, 20, 30, 40] },
    background: { type: Boolean, default: true },
    layout: { default: 'total, sizes, prev, pager, next, jumper' }
  },
  emits: ['pageChange'],
  setup(__props: any, { emit: __emit }) {

const props = __props

// 双向绑定：pageData 是props直接属性，vue设置为readonly 不可修改，props.pageData.key可修改，因此使用v-model，简化父组件调用子组件时的处理
let { pageNum, pageSize, totalCount } = toRefs(props.pageData)
const emit = __emit
// element-plus 不推荐监听 @size-change @current-change事件
watch([pageNum, pageSize], (newValue, oldValue) => {
  console.log('watch--pageChange', newValue, oldValue)
  emit('pageChange')
})

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      "current-page": _unref(pageNum),
      "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : pageNum = $event)),
      "page-size": _unref(pageSize),
      "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => (_isRef(pageSize) ? (pageSize).value = $event : pageSize = $event)),
      total: _unref(totalCount),
      "page-sizes": props.pageSizes,
      background: props.background,
      layout: props.layout
    }, null, 8, ["current-page", "page-size", "total", "page-sizes", "background", "layout"])
  ]))
}
}

})
import { Ref } from 'vue'
import { AllData, BaseObject, CB, ObjectList, StringOrNumberArray } from '@/types'
import type { AxiosInstance } from 'axios'
import { cloneDeep } from 'lodash-es'

interface SearchParams {
  startTime: string
  endTime?: string
  size?: number
  bmsLv3?: string
  segment?: string
}

interface StatusObj {
  loading: Ref<boolean>
  loadingError: Ref<boolean>
  partError?: boolean
}

// 数据分析查询
export const useAnalysisSearch = async (
  httpRequest: any, //AxiosInstance 接口api
  searchParams: BaseObject, //查询参数
  originalData: AllData, //原始allData对象，使用各个key
  timeKeyMap: string, //图表X轴数据在接口数据里的字段名，fixTime，sendTime 等
  statusObj?: StatusObj, //请求状态loading、loadingError
) => {
  let allData: AllData
  if (originalData) {
    allData = cloneDeep(originalData)
  } else {
    allData = {
      timeData: [], //时间，统一使用字段名 timeData
      volts: {}, //电压
      temp: {}, //温度
      tempDvalue: [], //温差
      realTotalCurrent: [], //电流
      voltDvalue: [], //压差
      soc: [],
    }
  }

  // hooks每次调用维护自己的loading，抛出给外部使用
  let loading = false
  let loadingError = false
  let partError = false //查询递归过程中，部分查询出错
  let prevSendTime = ''
  let startTimestamp = ''

  loading = true
  const getAnalysisData: CB = async () => {
    return httpRequest({
      ...searchParams,
      startTime: prevSendTime || searchParams.startTime || '',
      startTimestamp: startTimestamp || searchParams.startTimestamp || null,
      size: searchParams?.size ?? 500, //每次请求n条数据，最后一条数据的sendTime作为下次请求的startTime，循环请求直到返回数据为空
    })
      .then(async (res: any) => {
        // 拼接多次请求的数据
        if (res.data?.[timeKeyMap]?.length) {
          for (const key in allData) {
            if (key === 'temp') {
              if (Array.isArray(res.data.temp)) {
                if (!allData.temp) {
                  allData.temp = [] as StringOrNumberArray
                }
                allData.temp = (allData.temp as StringOrNumberArray).concat(res.data.temp || [])
              } else {
                if (!allData.temp) {
                  allData.temp = {} as ObjectList
                }
                // 类型检查 TODO
                for (const key in res.data.temp) {
                  if ((allData.temp as ObjectList)?.[key]) {
                    ;(allData.temp as ObjectList)[key] = (allData.temp as ObjectList)[key].concat(
                      res.data.temp[key] || [],
                    )
                  } else {
                    ;(allData.temp as ObjectList)[key] = res.data.temp[key] || []
                  }
                }
              }
            } else if (key === 'volts') {
              for (const key in res.data.volts) {
                if (allData.volts?.[key]) {
                  allData.volts[key] = allData.volts[key].concat(res.data.volts[key] || [])
                } else {
                  if (!allData.volts) {
                    allData.volts = {}
                  }
                  allData.volts[key] = res.data.volts[key] || []
                }
              }
            } else if (key === 'timeData') {
              allData.timeData = allData.timeData.concat(res.data[timeKeyMap] || [])
            } else {
              allData[key] = allData[key].concat(res.data[key] || [])
            }
          }

          // 记录上次请求的sendTime
          prevSendTime = allData.timeData[allData.timeData.length - 1]
          startTimestamp = res.data?.lastTimeStamp ?? null
          // 后端逻辑：每次返回数据条数随机，返回0条表示可终止，
          return getAnalysisData()
        } else {
          loading = false
          loadingError = false
          partError = false
          prevSendTime = ''
          return
        }
      })
      .catch((error: any) => {
        console.log(error)
        loading = false
        prevSendTime = ''
        if (allData.timeData?.length) {
          loadingError = false
          partError = true
          // ElMessageBox.alert('部分请求异常，将展示正常的数据，查看所有数据请再次查询', '系统提示')
        } else {
          loadingError = true
          partError = false
        }
        return
      })
  }
  await getAnalysisData()
  // console.log('---request after', loading, statusObj?.loading.value)
  if (statusObj) {
    statusObj.loading.value = loading
    statusObj.loadingError.value = loadingError
  }
  return {
    loading,
    loadingError,
    partError,
    allData,
  }
}

import { BaseObject } from '@/types'
import { cloneDeep } from 'lodash-es'

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path: string) {
  const isExternal = /^(https?:|http?:|mailto:|tel:)/.test(path)
  return isExternal
}

// 深度优先遍历，遍历数组、遍历树结构对象
export function deepTraversal(node: BaseObject | BaseObject[]) {
  let stack = []
  if (Array.isArray(node)) {
    stack = cloneDeep(node)
  } else {
    stack.push(node)
  }
  const nodeList = []
  while (stack.length) {
    const item: any = stack.pop() // push + pop 后进先出
    nodeList.push(item)
    const children = item.children || []
    for (let i = children.length - 1; i >= 0; i--) {
      //使用逆序push()，以便栈底pop()时子元素是正序
      stack.push(children[i])
    }
  }
  return nodeList
}

export function getRandom(min: number, max: number, precision?: number) {
  const num = Math.random() * (max - min) + min
  const temp = Number(num.toFixed(precision ?? 0))
  return temp
}
// 生成指定数值区间、指定个数的数组
export function getRandomArray(min: number, max: number, length: number, precision?: number) {
  let arr: number[] = []
  for (let i = 0; i < length; i++) {
    // const num = Math.floor(Math.random() * (max - min + 1)) + min //含最大值，含最小值
    const num = Math.random() * (max - min) + min
    const temp = Number(num.toFixed(precision ?? 0))
    arr.push(temp)
  }
  return arr
}

// 取一维、二维数组最小值
export function getMin(list: any[]) {
  let min = 0
  if (list.length) {
    if (Array.isArray(list[0])) {
      let minList = []
      for (let listItem of list) {
        let currentMin = Math.min(...listItem)
        minList.push(currentMin)
      }
      min = Math.min(...minList)
    } else {
      min = Math.min(...list)
    }
  }
  return min
}

// 取一维、二维数组最大值
export function getMax(list: any[]) {
  let max = 0
  if (list.length) {
    if (Array.isArray(list[0])) {
      let maxList = []
      for (let listItem of list) {
        let currentMax = Math.max(...listItem)
        maxList.push(currentMax)
      }
      max = Math.max(...maxList)
    } else {
      max = Math.max(...list)
    }
  }
  return max
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css'
import './assets/css/index.less'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import { vLoading } from 'element-plus/es/components/loading/src/directive'
import { globalRegister } from './global'

import '@/assets/iconfont/iconfont.js'

// import { createPinia } from 'pinia'
import './permission'
import store from '@/store'

const app = createApp(App)
// const pinia = createPinia()
app.use(ElementPlus)
// app.directive('load', vLoading)
app.use(globalRegister)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// app.component('WarnFilled', ElementPlusIconsVue.WarnFilled)

// app.use(pinia)
app.use(store)
app.use(router)
// app.mount('#app')

router.isReady().then(() => {
  app.mount('#app')
})

<!-- 组件设计：CommonSearch查询处理handleQuery放在父组件页面；
  CommonPagination数据pageData:{pageNum,pageSize,totalCount}在页面内使用hooks统一生成，可供CommonSearch、CommonPagination修改使用；
  CommonTable只做展示，不操作其他组件数据 -->
<template>
  <div class="common-pagination-component">
    <el-pagination
      v-model:current-page="pageNum"
      v-model:page-size="pageSize"
      :total="totalCount"
      :page-sizes="props.pageSizes"
      :background="props.background"
      :layout="props.layout"
    ></el-pagination>
  </div>
</template>

<script lang="ts" setup>
import { toRefs, watch } from 'vue'
interface PageData {
  pageNum: number
  pageSize: number
  totalCount: number
}
const props = withDefaults(
  defineProps<{
    pageData: PageData
    pageSizes?: number[]
    background?: boolean
    layout?: string
  }>(),
  {
    pageSizes: () => [10, 20, 30, 40],
    background: true,
    layout: 'total, sizes, prev, pager, next, jumper',
  },
)

// 双向绑定：pageData 是props直接属性，vue设置为readonly 不可修改，props.pageData.key可修改，因此使用v-model，简化父组件调用子组件时的处理
let { pageNum, pageSize, totalCount } = toRefs(props.pageData)
const emit = defineEmits(['pageChange'])
// element-plus 不推荐监听 @size-change @current-change事件
watch([pageNum, pageSize], (newValue, oldValue) => {
  console.log('watch--pageChange', newValue, oldValue)
  emit('pageChange')
})
</script>

<style scoped lang="less"></style>

import { App } from 'vue'
// import registerElement from './register-elements'
import registerProperties from './register-properties' 
import CommonForm from '@/components/CommonForm.vue'
import CommonSearchNew from '@/components/CommonSearchNew.vue'
import CommonTable from '@/components/CommonTable.vue'
import CommonPagination from '@/components/CommonPagination.vue' 
import SvgIcon from '@/components/SvgIcon.vue' //图表组件

export function globalRegister(app: App): void {
  // app.use(registerElement)
  app.use(registerProperties) 
  app.component('CommonForm', CommonForm)
  app.component('CommonSearchNew', CommonSearchNew)
  app.component('CommonTable', CommonTable)
  app.component('CommonPagination', CommonPagination) 
  app.component('SvgIcon', SvgIcon)
}
